let config = {
    s3: {
        REGION: "ap-southeast-2",
        BUCKET: "forwood-ozminerals-staging-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-ozminerals-staging-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "ap-southeast-2",
        URL: "https://j2dwwisvqzblbpedmfneqk5z44.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "ap-southeast-2",
        URL: "https://1wwflf0bfg.execute-api.ap-southeast-2.amazonaws.com/env",
        USER_TOKEN_URL: "https://ylidoden84.execute-api.ap-southeast-2.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.staging.ozminerals.forwoodsafety.com",
        WEBSOCKET: "wss://hea8dzwrh2.execute-api.ap-southeast-2.amazonaws.com/staging"
    },
    cognito: {
        REGION: "ap-southeast-2",
        USER_POOL_ID: "ap-southeast-2_u6AkKhc2D",
        IDENTITY_POOL_ID: "ap-southeast-2:4265e611-d43c-43d9-8e21-446d54bb76e6",
        USERPOOL_HOSTED_DOMAIN: "forwood-ozminerals-id-staging",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.staging.ozminerals.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.staging.ozminerals.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::284229493376:role/staging-CA"
    },
    samlProvider: {
      NAME: 'bhp',
      SUPPORTED_LIST: 'bhp,ForwoodAzure'
    },
    reactApp: {
        VERSION: "1.36.1",
        HOSTNAME: "id.staging.ozminerals.forwoodsafety.com",
        ENV_NAME: "staging",
        COOKIE_DOMAIN: ".staging.ozminerals.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "5632da98-3b6e-4fe5-9c26-ea3f8e3d8458",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.staging.ozminerals.forwoodsafety.com"
    },
    USERPOOL_ADFS_LAUNCH_URL(clientId) {
        return 'https://'+this.cognito.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.cognito.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.cognito.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+clientId;
    },
};

export default config;
